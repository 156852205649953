import { Injectable, TemplateRef } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NotificationType } from '@app-models/notification';
import { NotificationDisplayType } from '@app-models/notification/notification-display-type';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SuccessModalComponent } from '@app-components/modals/success/success-modal.component';
import { ApiUnavailableComponent } from '@app-components/modals/api-unavailable/api-unavailable.component';
import { ErrorModalComponent } from '@app-components/modals/error/error-modal.component';

/**
 * Manages notification for the application.
 */
@Injectable()
export class NotificationCenter {
  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly _modal: MatDialog,
  ) {}

  /**
   * Shows a success notification
   * @param message Success message to display.
   * @param display Display type. Defaults to snackbar.
   * @param templateContext Context to pass to a template
   */
  success(
    message: string | TemplateRef<unknown>,
    display: NotificationDisplayType = NotificationDisplayType.Snackbar,
    templateContext: Record<string, unknown> = undefined,
  ): void {
    switch (display) {
      case NotificationDisplayType.Modal:
        this.createModal(message, NotificationType.Success, templateContext);
        break;
      case NotificationDisplayType.Snackbar:
        this.createSnackBar(message, NotificationType.Success, templateContext);
        break;
    }
  }

  /**
   * Shows an error notification
   * @param message Error message to display.
   * @param display Display type. Defaults to snackbar.
   * @param title Optional title for modal
   * @param templateContext Context to pass to a template
   */
  error(
    message: string | TemplateRef<unknown>,
    display: NotificationDisplayType = NotificationDisplayType.Snackbar,
    templateContext: Record<string, unknown> = undefined,
    title?: string,
  ): void {
    switch (display) {
      case NotificationDisplayType.Modal:
        this.createModal(
          message,
          NotificationType.Error,
          templateContext,
          title,
        );
        break;
      case NotificationDisplayType.ApiUnavailableModal:
        this.createModal(
          message,
          NotificationType.APIUnavailable,
          templateContext,
        );
        break;
      case NotificationDisplayType.Snackbar:
        this.createSnackBar(message, NotificationType.Error, templateContext);
        break;
    }
  }
  private createModal(
    message: string | TemplateRef<unknown>,
    type: NotificationType,
    templateContext: Record<string, unknown> = undefined,
    title?: string,
  ): void {
    switch (type) {
      case NotificationType.Success:
        this._modal.open(SuccessModalComponent, {
          data: { message, templateContext },
          disableClose: true,
        });
        break;
      case NotificationType.Error:
        this._modal.open(ErrorModalComponent, {
          data: { title, message, templateContext },
          disableClose: true,
        });
        break;
      case NotificationType.APIUnavailable:
        this._modal.open(ApiUnavailableComponent, { disableClose: true });
        break;
    }
  }
  private createSnackBar(
    message: string | TemplateRef<unknown>,
    type: NotificationType,
    templateContext?: Record<string, unknown> | undefined,
  ): void {
    if (typeof message === 'string') {
      this._snackBar.open(message, 'Close', {
        panelClass: this.getNotificationTypeClass(type),
      });
    } else {
      this._snackBar.openFromTemplate(message, {
        panelClass: this.getNotificationTypeClass(type),
      }).instance.context = templateContext ?? {};
    }
  }
  private getNotificationTypeClass(type: NotificationType): string {
    switch (type) {
      case NotificationType.Error:
        return 'snackbar-error';
      case NotificationType.Success:
        return 'snackbar-success';
    }
  }
}
