import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'cp-cus-error-modal',
  templateUrl: './error-modal.component.html',
})
export class ErrorModalComponent {
  readonly isSimpleMessage: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected readonly data: {
      title?: string;
      message: string | TemplateRef<unknown>;
      templateContext: Record<string, unknown> | undefined;
    },
  ) {
    data.message;
    data.templateContext;

    this.isSimpleMessage = typeof this.data.message === 'string';
  }
}
