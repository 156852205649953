import { BaseClient } from '@app-clients/base.client';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { EventType } from '@app-models/logging/event-type';

@Injectable({
  providedIn: 'root',
})
export class LoggingClient extends BaseClient {
  constructor(httpClient: HttpClient, appEnv: AppEnvironment) {
    super('log', httpClient, appEnv);
  }

  /**
   * Logs an application event in the database
   * @param eventType Type of the event
   * @param context Optional. Context for the event
   */
  logEvent(eventType: EventType, context?: unknown): Promise<void> {
    return this.post(context ? { context: context.toString() } : {}, [
      eventType,
    ]);
  }
}
