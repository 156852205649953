import { NgModule } from '@angular/core';
import { LoaderComponent } from '@app-components/ui/loader/loader.component';
import { CommonModule } from '@angular/common';
import { MaterialCommonModule } from '@app-modules/material-common.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SpinnerComponent } from '@app-components/ui/spinner/spinner.component';
import { SearchBarComponent } from '@app-components/ui/search-bar/search-bar.component';
import { ObserveValueDirective } from '@app-directives/observe-value.directive';
import { InlineSpinnerComponent } from '@app-components/ui/inline-spinner/inline-spinner.component';
import { LabelViewComponent } from '@app-components/ui/label-view/label-view.component';
import { AssessmentContentRendererComponent } from '@app-components/ui/assessment-content-renderer/assessment-content-renderer.component';
import { CompassElementsModule } from '@highmatch/compass/elements';
import { CommonUiModule } from '@app-modules/ui/common-ui.module';
import { ReactiveFormsModule } from '@angular/forms';
import { IdentityComponent } from '@app-components/ui/custom-elements/identity/identity.component';
import { PendingAssessmentsComponent } from '@app-components/ui/custom-elements/pending-assessments/pending-assessments.component';
import { CustomElementComponent } from '@app-components/ui/custom-elements/custom-element/custom-element.component';
import { ReportComponent } from '@app-components/ui/custom-elements/report/report.component';

const exportedDeclarations = [
  LoaderComponent,
  SpinnerComponent,
  SearchBarComponent,
  ObserveValueDirective,
  InlineSpinnerComponent,
  LabelViewComponent,
  AssessmentContentRendererComponent,
  IdentityComponent,
  PendingAssessmentsComponent,
  ReportComponent,
];

/**
 * Holds @pages/logic related to main UI.
 */
@NgModule({
  declarations: [...exportedDeclarations, CustomElementComponent],
  exports: [...exportedDeclarations],
  imports: [
    CommonModule,
    MaterialCommonModule,
    MatMenuModule,
    MatTooltipModule,
    CompassElementsModule,
    CommonUiModule,
    ReactiveFormsModule,
  ],
})
export class UiModule {}
