import { Injectable } from '@angular/core';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { EventType } from '@app-models/logging/event-type';
import { LoggingClient } from '@app-clients/logging/logging.client';

/**
 * Service responsible for logging errors, events, etc.
 */
@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(
    private readonly _appEnvironment: AppEnvironment,
    private readonly _loggingClient: LoggingClient,
  ) {}

  /**
   * Logs an error to all error providers
   * @param error Error to log
   */
  logError(error: unknown): void {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  /**
   * Logs and event with optional context.
   * @param eventType Application event type
   * @param context Optional. Context for the event
   */
  logEvent(eventType: EventType, context?: unknown): void {
    this._loggingClient
      .logEvent(eventType, context)
      .catch(error => this.logError(error));
  }
}
